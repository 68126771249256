<template>
  <a :href="href">{{ email }}</a>
</template>

<script type="ts">
  import { Component, Vue } from "vue-property-decorator";

  /* @Component({}) */  // eslint hates this!?...
  export default class ObscuredEmail extends Vue {
    data() {
      const s = atob('aGVsbG9AaW50aW8udHY=')
      return {
        email: s,
        href: `mailto:${s}`,
      };
    }
  }
</script>
