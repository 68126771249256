
  import { Component, Prop, Vue } from "vue-property-decorator";
  import DebugDevice from "@/components/debug-device.vue";
  import DebugVideo from "@/components/debug-video.vue";

  @Component({
    components: {
      DebugDevice,
      DebugVideo,
    },
  })
  export default class Debug extends Vue {
  }
