
  import MultiRouterLink from "@/components/multi-router-link.vue";
  import ObscuredEmail from "@/components/obscured-email.vue";
  import SwitchTheLights from "@/components/switch-the-lights.vue";
  import Sticker from "@/components/sticker.vue";

  export default {
    name: "MainFooter",
    data: () => ({
      year: (new Date()).getFullYear(),
    }),
    components: {
      MultiRouterLink,
      ObscuredEmail,
      Sticker,
      SwitchTheLights,
    },
  };
