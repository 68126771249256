import axios from "axios"
import { StorageService } from "@/storage"
import { Event, Stream, EventStreams } from "@/models"

export class PerformaConfig {
  baseURL: string;
  storage: StorageService;
}

export class LoginRequest {
  email: string;
  password: string;
  organization: string;
}

export class ErrorResponse {
  errors: Array<string>;
  fieldErrors: {[index: string]: Array<string>};
  reason: any;
  constructor(data: any) {
    Object.assign(this, data);
  }
  static fromAPI(data: any): ErrorResponse {
    // TODO: unify/fix error responses on Performa's side
    const errors: Array<string> = [];
    const fieldErrors: {[index: string]: Array<string>} = {};
    if ("message" in data && typeof data.message == "string") {
      errors.push(data.message);
    }
    if ("error" in data) {
      if ((typeof data.error) == "string") {
        errors.push(data.error);
      } else if (Array.isArray(data.error)) {
        Array.prototype.push.apply(errors, data.error);
      } else {
        Object.entries(data.error).map(([k, v]) => {
          if (!(v instanceof Array)) {
            return;
          }
          if (k == "non_field_errors") {
            Array.prototype.push.apply(errors, v);
          } else {
            fieldErrors[k] = v;
          }
        })
      }
    }
    return new ErrorResponse({errors, fieldErrors, reason: data});
  }
}

export class PerformaClient {
  token: string | null = null;
  axios: any;
  storage: StorageService;
  eventsById: any;
  requestsPending = 0;
  language: string | null = null;

  constructor(config: PerformaConfig) {
    this.storage = config.storage;
    this.eventsById = {};
    this.axios = axios.create({
      baseURL: config.baseURL,
    });
    this.axios.interceptors.request.use(
      (config: any) => this.interceptRequest(config),
      Promise.reject
    );
    this.axios.interceptors.response.use(
      (response: any) => this.interceptResponse(response),
      (error: any) => this.interceptResponseError(error),
    );
  }

  interceptRequest(config: any): any {
    this.requestsPending++;
    if (this.language) {
      config.headers["Accept-Language"] = this.language;
    }
    if (this.token) {
      config.headers["Authorization"] = "token " + this.token;
    }
    return config;
  }

  interceptResponse(response: any): any {
    this.requestsPending--;
    return response;
  }

  interceptResponseError(error: any): any {
    this.requestsPending--;
    if (error.response) {
      const response = error.response;
      if (response.status == 401) {
        this.saveToken(null);
      }
      if (400 <= response.status && response.status < 500) {
        return Promise.reject(ErrorResponse.fromAPI(response.data));
      } else if (500 <= response.status && response.status < 600) {
        return Promise.reject(new ErrorResponse({errors: ["Internal Server Error"]}));
      }
    }
    return Promise.reject(error);
  }

  get isLoading(): boolean {
    return this.requestsPending > 0;
  }

  withContext(request: any): any {
    const alt = {
      return_domain: window.location.hostname,
    };
    return Object.assign({}, request, alt);
  }

  async listEvents(query: any): Promise<Array<Event>> {
    const response = await this.axios.get("/api/v1/events/", {params: query});
    const events = response.data.results.map((e: any) => Event.fromAPI(e));
    events.forEach((e: any) => {
      this.eventsById[e.id] = e;
    })
    return events;
  }

  async getEvent(id: string): Promise<Event> {
    if (this.eventsById[id]) {
      return this.eventsById[id];
    }
    const response = await this.axios.get(`/api/v1/events/${id}/`);
    const event = Event.fromAPI(response.data);
    this.eventsById[event.id] = event;
    return event;
  }

  async getEventStreams(event: Event): Promise<EventStreams> {
    return await this.getEventStreamsById(event.id);
  }

  async getEventStreamsById(id: string): Promise<EventStreams> {
    const response = await this.axios.get(`/api/v1/events/${id}/streams/`);
    return EventStreams.fromAPI(response.data);
  }

  async restoreToken(): Promise<void> {
    this.token = await this.storage.getItem("token") as string;
  }

  async saveToken(token: string | null): Promise<void> {
    this.token = token;
    if (token) {
      await this.storage.setItem("token", token);
    } else {
      await this.storage.deleteItem("token");
    }
  }

  async login(request: LoginRequest): Promise<void> {
    const response = await this.axios.post("/api/v1/auth/login/",
                                           this.withContext(request));
    await this.saveToken(response.data.token);
    return;
  }

  async logout(): Promise<void> {
    await this.axios.post("/api/v1/auth/logout/");
    await this.saveToken(null);
    return;
  }

  get isAuthenticated(): boolean {
    return !!this.token;
  }

}
