
  import { Component, Vue } from "vue-property-decorator";

  @Component
  export default class SwitchTheLights extends Vue {
    forceColorSchemeLight(): void {
      document.documentElement.setAttribute("data-color-scheme", "light");
    }
    forceColorSchemeDark(): void {
      document.documentElement.setAttribute("data-color-scheme", "dark");
    }
  }
