
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { Route, RawLocation } from "vue-router";
  import { getRouter, hostRoutes } from "@/routes";

  @Component({})
  export default class MultiRouterLink extends Vue {
    @Prop({}) to: RawLocation;

    externalUrl = "";

    created(): void {
      this.externalUrl = "";
      if (typeof(this.to) == "string") {
        this.externalUrl = this.to;
        return;
      }
      const cs = this.$router.getMatchedComponents(this.to);
      if (cs.length > 0) {
        // OK, will resolve as an internal link
        return;
      }

      // Look for something using alternative routers
      for (const [host, _routes] of Object.entries(hostRoutes)) {
        const router = getRouter(host);
        const cs = router.getMatchedComponents(this.to)
        if (cs.length == 0) {
          continue;
        }
        // Got one
        const route = router.resolve(this.to);
        const base = window.location.protocol + "//" + host;
        const url = new URL(route.href, base);
        this.externalUrl = url.toString();
        return;
      }
      // Fall back on default router-link behavior
    }

  }
