
  import Sticker from "@/components/sticker.vue";

  export default {
    /* eslint-disable-next-line vue/multi-word-component-names */
    name: "Imprint",
    components: {
      Sticker,
    }
  };
