class FromAPI {
  constructor(data: any) {
    Object.assign(this, data);
  }
  static fromAPI(data: any): any {
    throw new Error("unimplemented");
  }
}

export class Event extends FromAPI {
  id: string;
  title: string;

  static fromAPI(data: any): Event {
    return new Event({
      id: data.id,
      title: data.title,
    });
  }
}

export enum PlaybackStatus {
  upcoming = "upcoming",
  live = "live",
  vod = "vod",
  unknown = "unknown"
}

export class Stream extends FromAPI {
  id: string;
  url: string;
  playbackStatus: PlaybackStatus;

  static fromAPI(data: any): Stream {
    return new Stream({
      id: data.id,
      url: data.url,
      playbackStatus: data.playback_status,
    });
  }

  get isPlayable(): boolean {
    return (this.playbackStatus == PlaybackStatus.live
      || this.playbackStatus == PlaybackStatus.vod);
  }

}

export class EventStreams extends FromAPI {
  eventId: string;
  embed: string;
  streams: Array<Stream>;

  static fromAPI(data: any): EventStreams {
    return new EventStreams({
      eventId: data.id,
      embed: data.embed,
      streams: data.streams.map((s: any) => Stream.fromAPI(s)),
    });
  }
}
