
  import { Component, Prop, Vue } from "vue-property-decorator";

  enum ColorScheme {
    Light = 'light',
    Dark = 'dark',
  }

  class LayerSpec {
    name?: string;
    showIf?: ColorScheme;
  }

  @Component
  export default class Sticker extends Vue {
    @Prop({required: true}) readonly img!: string;
    @Prop({default: ""}) readonly alt!: string;

    @Prop({
      default: () => [
        {name: 'fill', showIf: ColorScheme.Dark},
        {},
      ]
    }) readonly layerSpecs!: Array<LayerSpec>;

    get layers(): Iterable<{src: string; cls: string}> {
      return this.layerSpecs.map((spec: LayerSpec) => {
        // Note: Webpack performs static source analysis.
        // Make sure 'require' gets some hints about where to look for the assets.
        let src = '';
        if (spec.name) {
          src = require(`@/assets/img/stickers/${this.img}-${spec.name}.svg`);
        } else {
          src = require(`@/assets/img/stickers/${this.img}.svg`);
        }
        const clss = ['layer'];
        if (spec.showIf == ColorScheme.Dark) {
          clss.push('prefers-dark-transition');
        }
        if (spec.showIf == ColorScheme.Light) {
          clss.push('prefers-light-transition');
        }
        return {
          src: src,
          cls: clss.join(' '),
        };
      });
    }

    get scaffold() {
      return require(`@/assets/img/stickers/${this.img}.svg`);
    }
  }
