import * as v from "voca";
import * as uuid from "uuid";

enum Type {
  UUID = "UUID",
  URL = "URL",
}

class WhatIs {
  constructor(
    public type: Type | null,
    public value: string | number | URL | null,
  ) {}
}

function whatisURL(that: string): URL | null {
  let url = null
  if (that.match("://")) {
    try {
      url = new URL(that);
    } catch (e) { /* eslint-disable no-empty */ }
  } else {
    try {
      url = new URL("https://" + that);
    } catch (e) { /* eslint-disable no-empty */ }
  }
  if (!url) {
    return null;
  }

  return url;
}

function whatis(that: string): WhatIs {
  that = v.trimLeft(that, "/");
  if (uuid.validate(that)) {
    return new WhatIs(
      Type.UUID,
      that,
    );
  }
  if (v.isNumeric(that)) {
    return new WhatIs(
      null,
      Number.parseFloat(that),
    );
  }
  const url = whatisURL(that);
  if (url) {
    return new WhatIs(
      Type.URL,
      url,
    );
  }
  return new WhatIs(null, that);
}

export {
  WhatIs,
  whatis,
}
