export interface StorageService {
  getItem(key: string): Promise<any>;
  setItem(key: string, value: any): Promise<void>;
  deleteItem(key: string): Promise<void>;
}

export class LocalStorageService implements StorageService {
  async getItem(key: string): Promise<any> {
    return window.localStorage.getItem(key);
  }
  async setItem(key: string, value: any): Promise<void> {
    window.localStorage.setItem(key, value);
    return;
  }
  async deleteItem(key: string): Promise<void> {
    window.localStorage.removeItem(key);
    return;
  }
}
