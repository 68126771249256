
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { getUA } from "@/ua";

  @Component
  export default class DebugDevice extends Vue {
    ua: any | null = null;
    mounted(): void {
      this.ua = getUA();
    }
    get window(): any {
      return window;
    }
  }
