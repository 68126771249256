import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Welcome from "@/views/welcome.vue";
import UnderConstruction from "@/views/under-construction.vue";
import Imprint from "@/views/imprint.vue";
import PurchaseOrder from "@/views/purchase-order.vue";
import Play from "@/views/play.vue";
import Debug from "@/views/debug.vue";
import NotFound from "@/views/not-found.vue";

Vue.use(VueRouter);

const defaultHost = "www.intio.tv";

function playRoutes(prefix: string): Array<RouteConfig> {
  return [
    {
      path: prefix + "/",
      name: "play-nothing",
      component: Play,
    },
    {
      path: prefix + "/:what(.*)",
      name: "play-something",
      props: true,
      component: Play,
    },
  ];
}

const hostRoutes: { [host: string]: Array<RouteConfig> } = {
  [defaultHost]: Array.prototype.concat(
    [
      // {path: "/", name: "welcome", component: Welcome},
      {path: "/", name: "index", component: UnderConstruction},
      {path: "/imprint", name: "imprint", component: Imprint},
      {path: "/debug", name: "debug", component: Debug},
      {path: "/purchase-order", name: "purchase-order", component: PurchaseOrder},
    ],
    playRoutes("/play"),
    [
      {path: "*", component: NotFound},
    ],
  ),
  "play.intio.tv": Array.prototype.concat(
    {path: "/", redirect: "/play/"},
    playRoutes("/play"),
    [
      {path: "*", component: NotFound},
    ],
  ),
};

function getRouter(host: string): VueRouter {
  const routes = hostRoutes[host] || hostRoutes[defaultHost];
  return new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routes,
  })
}

export {
  getRouter,
  hostRoutes,
  defaultHost,
}
