const environments: any = {
    development: {
        DEBUG: true,
    },
    production: {
        DEBUG: false,
    }
}

export const settings = environments[process.env.NODE_ENV];
