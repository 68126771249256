
  import { Component, Vue } from "vue-property-decorator";
  import MainFooter from "@/components/main-footer.vue";
  import { version } from "../package.json";
  import { settings } from "./settings";

  @Component({
    components: {MainFooter},
  })
  export default class App extends Vue {
    debug = false;
    version = version;
    width = 0;

    async mounted(): Promise<void> {
      (window as any).debug = (value = true) => {
        window.addEventListener("resize", this.onWindowResize);
        this.onWindowResize();
        this.debug = value;
        Object.assign(window, {
          app: this.$root,
        });
      };

      if (settings.DEBUG) {
        (window as any).debug();
      }
    }
    onWindowResize(): void {
      this.width = window.innerWidth;
    }
  }
