
  import { Component, Prop, Vue } from "vue-property-decorator";
  import videojs from "video.js";
  import "video.js/dist/video-js.css";
  import "@/styles/vjs-theme-intio.scss";

  @Component({})
  export default class Player extends Vue {
    @Prop({default: {}}) options: any;
    @Prop({default: true}) fluid: boolean;
    player: any = null;

    mounted(): void {
      if (this.$refs.player instanceof Element) {
        this.player = videojs(
          this.$refs.player,
          this.options,
          function onPlayerReady() {
            console.log("onPlayerReady");
          },
        );
      }
    }

    beforeDestroy() {
      if (this.player) {
        this.player.dispose()
      }
    }

  }
