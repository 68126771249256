 import { UAParser } from "ua-parser-js";

export function getUA(): any {
    const ua = (new UAParser()).getResult()
    if (ua && ua.device) {
        const device = ua.device as any;
        device.maxTouchPoints = window.navigator.maxTouchPoints;
    }
    return ua;
}
