
  import { Component, Prop, Vue } from "vue-property-decorator";
  import DebugVideo from "@/components/debug-video.vue";
  import Player from "@/components/player.vue";
  import { whatis } from "@/whatis";
  import { PerformaClient } from "@/performa";
  import { LocalStorageService } from "@/storage"

  @Component({
    components: {
      DebugVideo,
      Player,
    },
  })
  export default class Play extends Vue {
    @Prop({default: ""}) what: string;

    whatNext: string;
    options: any = null;
    message = "";

    async created(): Promise<void> {
      this.whatNext = this.what;
      await this.setupPlayer(this.what);
    }

    showHttpError(e: any): string {
      if (e.errors && e.errors.length > 0) {
        return e.errors[0];
      }
      if (e.response) {
        switch (e.response.status) {
          case 401:
            return "Sorry, we can't show you this content";
          case 404:
            return "No event found here";
          case 500:
            return "We can't play this because the server is on fire";
        }
      }
      return "There was a problem.";
    }

    async setupPlayer(what: string): Promise<void> {
      this.options = null;
      this.message = "";
      const wi = whatis(what);
      switch (wi.type) {
        case "URL":
          this.options = {
            sources: [
              {src: (wi.value as URL).toString()},
            ],
          };
          return;
        case "UUID": {
          const performa = new PerformaClient({
            baseURL: "https://live.performa.intio.tv",
            storage: new LocalStorageService(),
          });
          let eventStreams;
          try {
            eventStreams = await performa.getEventStreamsById(wi.value as string);
          } catch (e) {
            this.message = this.showHttpError(e);
            return;
          }
          if (eventStreams.streams.length == 0) {
            this.message = "No streams found here";
            return;
          }
          const stream = eventStreams.streams[0];
          if (stream.isPlayable) {
            this.options = {
              sources: [
                {src: stream.url},
              ],
            };
            return;
          }
          return;
        }
      }
    }

    clickWatch(): void {
      if (this.what == this.whatNext) {
        return;
      }
      // TODO: Can we make Vue Router obey our wishes here?
      //
      // The problem is, with /play/:what(.*) captures, the prop gets mangled in crazy
      // ways on all attempts at Vue-native procedural navigation, which is bad.
      // We want our URLs to be like:
      //
      // - https://intio.tv/play/https://example.com/hls.m3u8
      // - https://intio.tv/play/example.com/hls.m3u8
      // - https://play.intio.tv/example.com/hls.m3u8
      //
      // For now, we use the browser API directly.
      window.location.assign("/play/" + this.whatNext);
    }
  }
