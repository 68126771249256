
  import { Component, Prop, Vue } from "vue-property-decorator";

  @Component
  export default class DebugVideo extends Vue {
    testTypes = [
      "application/vnd.apple.mpegurl",
      "application/x-mpegURL",
      "application/dash+xml",
      "video/mp4",
      "video/webm",
      "video/ogg",
    ];
    testCodecs = [
      "hev1.1.6.L123.B0,mp4a.40.2",
      "avc1.4d4029,mp4a.40.2",
    ];

    video: any;

    created(): void {
      this.video = document.createElement("video");
    }

    canPlayType(t: string): any {
      return this.video.canPlayType(t) || "unknown";
    }

    canPlayCodec(t: string): any {
      if (!window.MediaSource) {
        return "no MediaSource";
      }
      return window.MediaSource.isTypeSupported(`video/mp4; codecs="${t}"`)
  }

  }
