import Vue from "vue";
import App from "./App.vue";
import { getRouter } from "./routes";
import { version } from "../package.json";
import { settings } from "./settings";

Vue.config.productionTip = false;

const router = getRouter(window.location.host);

const app = new Vue({
  router,
  created: () => {
    document.documentElement.setAttribute(
      "data-color-scheme",
      window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" :
        window.matchMedia("(prefers-color-scheme: light)").matches ? "light" :
          "no-preference",
      )
  },
  render: h => h(App),
});
app.$mount("#app");
