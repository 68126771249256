
  import { Component, Prop, Vue } from "vue-property-decorator";
  import Sticker from "@/components/sticker.vue";

  class Item {
    name: string;
    price: number;
    quantity = 0;
    subtotal = 0;
    constructor(data: any) {
      Object.assign(this, data);
    }
    updateSubtotal(): void {
      this.quantity = Math.floor(this.quantity);
      if (this.quantity < 0 || isNaN(this.quantity)) {
        this.quantity = 0;
      }
      this.subtotal = this.price * this.quantity;
    }
  }

  @Component({
    components: {Sticker},
  })
  export default class PurchaseOrder extends Vue {
    @Prop({default: []}) items: Array<Item>;
    @Prop({default: 0}) total: number;

    created(): void {
      this.items = [
        new Item({name: "Software development", price: 320}),
        new Item({name: "Embedded development", price: 480}),
        new Item({name: "Hardware R&D", price: 360}),
        new Item({name: "AI development", price: 630}),
        new Item({name: "Maintenance and upgrades", price: 200}),
      ];
    }

    updateTotal(): void {
      this.total = this.items.reduce((a, i) => a + i.subtotal, 0);
    }

    async onSubmit(): Promise<void> {
      const totalHours = this.items.reduce((a, i) => a + i.quantity, 0);
      if (totalHours <= 0) {
        window.alert("Please book at least one hour.");
        return;
      }
      window.alert("(Placeholder for ZEN payment integration.)");
    }

  }
